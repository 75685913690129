import { TikTok } from "react-tiktok";
import brayton from "./images/brayton.jpg";
function App() {
  return (
    <div>
      <h1>Brayton Ercanbrack</h1>
      <h1>
        Brayton Ercanbrack is a high schooler who went viral for wearing a black
        face costume on Halloween in 2022. In October of 2022, Brayton decided
        to wear a black face costume for Halloween. He was recorded in public
        wearing black makeup and a prisoner costume, and it quickly went viral.
        The photos sparked a national conversation about race and racism, with
        many people condemning Brayton's actions.
      </h1>

      <TikTok url="https://www.tiktok.com/@auntkaren0/video/7161042444371643694?is_copy_url=1&is_from_webapp=v1&lang=en" />

      <div
        style={{
          display: "flex",
          flexDirection: "row",
        }}
      >
        <div style={{ flex: 1 }}></div>
        <img src={brayton} alt="Brayton Ercanbrack" />
        <div style={{ flex: 1 }}></div>
      </div>
    </div>
  );
}

export default App;
